import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
export default class PersonalForm extends Component {

    constructor() {
        super();
        this.state = {
            nume: "",
            dn: "",
            email: "",
            serieCi: "",
            numarCi: "",
            judet: "",
            recordGenerationTime: ''
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event){
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
        console.log(this.state.nume + this.state.email )
    }

    handleSubmit(event){
        event.preventDefault();
        if (this.state.nume === "" || this.state.email === "" || this.state.dn === "") {
            alert("Toate campurile sunt obligatorii va rog completatile")
        } else {

            const data = {
                nume: this.state.nume,
                dn: this.state.dn,
                email: this.state.email,
                numarCi: this.state.numarCi,
                serieCi: this.state.serieCi,
                judet: this.state.judet
            };
    
            // Ambele metode sunt valabile dar este o problema cu button type submit in firefox
    
            // fetch('http://127.0.0.1:8082/api/v1/student', {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(data)
            // })
    
            axios.post('http://com.elitud.ro:8082/api/v1/student', data)
            .then(response =>{
                this.setState({
                    output:response.data.success,
                    recordGenerationTime: new Date().getTime()
                })
                console.log(this.response)
            } )
            .catch(error => {
                this.setState({ errorMessage: error.message });
                console.error('There was an error!', error);
            });
            

        }


    }

    render() {
        return (
            <div className="jumbotron">
                <h1 className="display-4">Preluare date personal</h1>
                <hr className="my-4"></hr>
                {/* <h3>{this.state.nume}</h3> */}
                {/* <h3>{this.state.dn}</h3> */}
                {/* <h3>{this.state.email}</h3> */}
                
                <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Nume </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Nume si prenume" 
                            name="nume" 
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Data nasterii </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Data nasterii" 
                            name="dn"
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>Adresa email</Form.Label>
                        <Form.Control 
                            type="email" 
                            placeholder="Adresa email" 
                            name="email"
                            onChange={this.handleChange}
                        />
                        <Form.Text className="text-muted">
                        Adresa ta de email nu va fi partajata cu nimeni.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Serie carte identitate </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Serie CI" 
                            name="serieCi"
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Numar carte identitate </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Numar CI" 
                            name="numarCi"
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>Judet </Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Judet" 
                            name="judet"
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <br />
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
                <hr className="my-4"></hr>
            </div>
        ) 

    }

}