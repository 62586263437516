import React from "react";
import HrForms from "./HrForms";
import TabelHr from "./TabelHr";

const Hr = (props) => {

    return(
        <div>
            <HrForms />
        </div>

    )

};

export default Hr;