import React, {useState, useEffect} from 'react';
import { Table } from "react-bootstrap";
import { Button} from 'react-bootstrap';
import Form from "react-bootstrap/Form";
import axios from "axios";


function TabelHr (props) {

    const deleteRequestHandler = (id) => event => {
        event.preventDefault();
        const response = axios.delete(
            `http://127.0.0.1:8082/api/v3/hr/${id}`
        );
        // O sa introduc partea de modificare a state-ului pentru parinte
       //props.onChange(event.target.value);
    };
        const handleChange = (event) => {
            event.preventDefault();
            let numarId = event.target.id;
            let numeId = "nume" + numarId;
            alert("Am selectat randul cu id: " + numarId + 
            document.getElementById(numeId).innerHTML );
        }

    // function handleChange(event) {
    //     props.onChange(event.target.value);
    // }

    return (
        
        // <Table responsive striped bordered hover variant="dark">
        <div>
        <div class="row" className="hdr">    
        <div class="col-sm-12 btn btn-success">    
        Personal activ in cadrul institutiei   
         </div>    
          </div>  
        <Table responsive bordered hover >
        <thead>
            <tr>
                <td></td>
                <td>Id</td>
                <td xs={4}>Nume</td>
                <td xs={4}>Prenume</td>
                <td>CNP</td>
                <td>Sr.CI</td>
                <td>Nr.CI</td>
                <td>Email</td>
                <td>Localitate</td>
                <td>Judet</td>
                <td>Telefon</td>
                <td > ACTIUNI </td>
            </tr>
        </thead>
        <tbody>
                { props.obHr?.map(hr => <tr key ={hr.id} >
                    <td>
                        <Form.Check 
                            type="checkbox"
                            id={hr.id}
                            onChange = {handleChange}/> </td>
                    <td> {hr.id} </td>
                    <td id ={"nume" + hr.id}> {hr.nume} </td>
                    <td id ={"prenume" + hr.id}> {hr.prenume} </td>
                    <td id ={"cnp" + hr.id}> {hr.cnp} </td>
                    <td id ={"serieCi" + hr.id}> {hr.serieCi} </td>
                    <td id ={"numarCi" + hr.id}> {hr.numarCi} </td>
                    <td id ={"email" + hr.id}> {hr.email} </td>
                    <td id ={"localitate" + hr.id}> {hr.localitate} </td>
                    <td id ={"judet" + hr.id}> {hr.judet} </td>
                    <td id ={"telefon" + hr.id}> {hr.telefon} </td>
                    <td > <Button  onClick={(e)=>deleteRequestHandler(hr.id)(e)}  variant="outline-danger">Sterg</Button> </td>
                </tr>)}
        </tbody>
    </Table>
    </div>
    );


}
export default TabelHr;