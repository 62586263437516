import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import axios from "axios";
import TabelHr from "./TabelHr";
import DialogModal from "./DialogModal";
import { Col, Container } from "react-bootstrap";

function HrForms() {

    const dialog = false;
    const [st, setHr] = useState();
    
    //const [value, setValue] = useState("Neapsat");
    //function handleChange(newValue) {
    //    setValue(newValue);
    //    console.log("Am apasat butonul de delete s-a schimbat value: " + value)
    //}
    // DEclar drept functie  executia unei cereri de tip GET  cu axios




    const axiosPost = async (d) => {
        axios.post('http://com.elitud.ro:8082/api/v3/hr', d)
        .then(response => 
            console.log('Starting Request', JSON.stringify(null, null, 2)))
        .catch(error => {
                //this.setState({ errorMessage: error.message });
                console.error('There was an error!', error);
            });
    }

    const axiosGet = async () => {
        axios.get('http://127.0.0.1:8082/api/v3/hr', { crossdomain: true })
        .then(raspuns=> {
            const hr = raspuns.data;
            setHr(hr);
            });
    };
    
    useEffect(() => {
        axiosGet();
    }, []);
    //console.log(st);
    
    //const text = 'Trimite';

    const valoriInitiale = {
        nume: "",
        prenume: "",
        email:"",
        cnp:"",
        serieCi:"",
        numarCi:"",
        localitate:"",
        judet:"",
        telefon:""
    };

    const [{nume, prenume, cnp, serieCi, numarCi, email, localitate, judet, telefon}, 
        setState] = useState(valoriInitiale);

    const reseForms = () => {
        setState({...valoriInitiale, })
    }

    const onCh = (e) =>{
        const {name, value} = e.target;
        setState(prevState => ({...prevState, [name]: value}));
    }


    const submit = (e) => {
        e.preventDefault();
        const data = {
            nume: nume,
            prenume: prenume,
            cnp: cnp,
            serieCi:serieCi,
            numarCi:numarCi,
            email: email,
            localitate: localitate,
            judet: judet,
            telefon: telefon

        };
            

            axiosPost(data);
            e.target.reset();
            axiosGet();
            
            
    
    }
    
    const personal = () => {
        return {

        }
    }



    return (
        <Container >
        <div className="jumbotron">
        <h1 className="display-4">Editare date personal</h1>
        <hr className="my-4"></hr>
        <Form onSubmit={submit}>
        <Row >
            <Col>
            <Form.Group controlId="formBasicText">
            <Form.Label>Nume</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Nume" 
                    name="nume" 
                    onChange={onCh}
                    />
            </Form.Group>
            </Col>
            <Col>
            <Form.Group controlId="formBasicText">
                <Form.Label>Prenume</Form.Label>
                <Form.Control 
                    type="text" 
                    placeholder="Prenume" 
                    name="prenume"
                    onChange={onCh}
                />
            </Form.Group>
            </Col>
            </Row>
            <Row >
                <Col>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>CNP</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="CNP" 
                        name="cnp"
                        onChange={onCh}
                    />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>Serie CI</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Serie CI" 
                        name="serieCi"
                        onChange={onCh}
                    />
                    </Form.Group>               
                </Col>
                <Col>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>Numar CI</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Numar CI" 
                        name="numarCi"
                        onChange={onCh}
                    />
                    </Form.Group>                
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-mail personal</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="E-mail personal" 
                        name="email"
                        onChange={onCh}
                        />
                    </Form.Group>                
                </Col>
                <Col xs={4}>
                    <Form.Group controlId="formBasicEmail">
                    <Form.Label>E-mail serviciu</Form.Label>
                    <Form.Control 
                        type="email" 
                        placeholder="E-mail serviciu" 
                        name="emailj"
                        onChange={onCh}
                        />
                    </Form.Group>                
                </Col>
                <Col xs={2}>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>Telefon</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Telefon" 
                        name="telefon"
                        onChange={onCh}
                    />
                    </Form.Group>           
                </Col>
                <Col>
                    <Form.Group controlId="formBasicText">
                        <Form.Label>C.P.</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="CP"
                            name="cp"
                            onChange={onCh}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>Localitate</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Localitate" 
                        name="localitate"
                        onChange={onCh}
                    />
                    </Form.Group>                   
                </Col>
                <Col>
                    <Form.Group controlId="formBasicText">
                    <Form.Label>Judet</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Judet" 
                        name="judet"
                        onChange={onCh}
                    />
                    </Form.Group>                  
                </Col>
            </Row>
            <br />
            <Button variant="primary" type="submit">
            Submit
            </Button>
        </Form>
        <hr className="my-4"></hr>
    
        <DialogModal s = {dialog} />
        </div>
           
            <TabelHr obHr = {st}/>
           

        </Container>
    )
    
};

export default HrForms;