import React from "react";
import { Table } from "react-bootstrap";


function TabelPersonal(props) {

    return (
        <Table responsive striped bordered hover variant="dark">
            <thead>
                <tr>
                    <td>Id</td>
                    <td>Nume</td>
                    <td>Email</td>
                    <td>Sr.</td>
                    <td>Nr.</td>
                    <td>Judet</td>
                </tr>
            </thead>
            <tbody>
                { props.personal.map(student => <tr key ={student.id} >
                    <td> {student.id} </td>
                    <td> {student.nume} </td>
                    <td> {student.email} </td>
                    <td> {student.serieCi} </td>
                    <td> {student.numarCi} </td>
                    <td> {student.judet} </td>
                </tr>)}
            </tbody>
        </Table>
    )

}


 export default TabelPersonal;