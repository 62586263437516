import React from "react";
import PersonalForm from "./PersonalForms";
import TabelPersonal from "./TabelPersonal";
import { Container} from 'react-bootstrap';

const Personal = (props) => {return (
    <Container>
        <PersonalForm />
        <TabelPersonal personal={props.personal} />
    </Container>
)};

export default Personal;