import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";


function DialogModal (props) {
    const [view, setShow] = useState(props.s);

    const handleClose = () => setShow(false);
    console.log("Am transmis catre modal valoarea lui props.s  :" + props.s);

    return (
        <Modal 
        show = {view} 
        onHide = {handleClose} 
        backdrop = "static" 
        keyboard = {false}
        >
            <Modal.Header closeButton>
                <Modal.Title>Date trimise in baza </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Aici o sa introduc datele ce se doresc a fi 
                afisate in clipa in care totul este functional
            </Modal.Body>
            <Modal.Footer>
                <Button varinat="secondary" onClick = {handleClose} >
                    Devalidez    
                </Button>
                <Button variant = "primary" >
                    Validez
                </Button>    
            </Modal.Footer> 
        </Modal>

        )
}
export default DialogModal;