import React from "react";
//import PersonalForm from "./Componente/PersonalForms";
//import TabelPersonal from "./Componente/TabelPersonal";
import { Container, Nav, 
Navbar } from "react-bootstrap";
import Personal from "./Componente/Personal";
import Adresa from "./Componente/Adresa";
import Hr from "./Componente/Hr.js";
import ChatRoom from "./Componente/ChatRoom.js"
import axios from "axios";
import {
  BrowserRouter as Router, 
  Routes, 
  Route, 
  Link
} from "react-router-dom";


class App extends React.Component {
  constructor(){
    super();
    this.state = {
      contor : 0, 
      student : []
      }
      this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
      this.fetchContacts();
    }

  
    componentDidUpdate(prevProps) {
      if(prevProps.recordGenerationTime !== this.props.recordGenerationTime) {
        this.fetchContacts();
      }
      // this.fetchContacts();
    }

    fetchContacts = () => {
      axios.get('http://127.0.0.1:8082/api/v1/student', { crossdomain: true })
      .then(res=> {
        console.log(res);
        const student = res.data;
        console.log(res.data);
        this.setState({student});
      })
    }

  handleClick(){
    this.setState(initiala=>{
      return {
        contor: initiala.contor +1
      }
    })

  }

  render(){

    return (
      <Container fluid>
      <Router>
        <Navbar bg="success" variant="dark" sticky="top">
        <Container fluid>
          <Navbar.Brand as={Link} to="/">Navbar</Navbar.Brand>
          <Nav variant="pills" className="justify-content-center" defaultActiveKey="/">
            <Nav.Link as={Link} to="/">Acasa</Nav.Link>
            <Nav.Link as={Link} to="/chat">ChatRoom</Nav.Link>
            <Nav.Link as={Link} to="/hr">Hr</Nav.Link>
          </Nav>
          </Container>
        </Navbar>
        {/*De aici incepe  partea efectiva a React Router*/ }
      <Routes>
        {/* <Route path="/" element={<App />} /> */}
        <Route path="/" element={<Personal personal={this.state.student}/>} />
        <Route path="/chat" element={<ChatRoom />} />
        <Route path="/hr" element={<Hr/>} />
        {/* <Route exact path="/">           
            <Personal personal={this.state.student}/>
        </Route>
        <Route path="/adresa">
            <Adresa />
        </Route>
        <Route path="/hr">
            <Hr />
        </Route> */}
      </Routes>
      </Router>
      </Container>
    );

  }

}

export default App;
