import React, {Component} from "react";
import Button from "react-bootstrap/Button";
import {Form,Container} from "react-bootstrap";
import axios from "axios";

export default class AdresaForms extends Component {

    constructor(){
        super();

        this.state = {
            denumire: "",
            strada: "",
            cartier: "",
            localitate: "",
            judet: "",
            numar: "",
            bloc: "",
            scara: "",
            etaj: 0,
            apartament:""

        }   
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    handleChange(event){
        const {name, value} = event.target;
        this.setState({
            [name]: value
        })
        console.log(this.state.denumire );
    }

    handleSubmit(event){
        event.preventDefault();
        if (this.state.denumire === "" || this.state.strada === "" || this.state.judet === "") {
            alert("Toate campurile sunt obligatorii va rog completatile")
        } else {

            const data = {
                denumire: this.state.denumire,
                strada: this.state.strada,
                cartier: this.state.cartier,
                localitate: this.state.localitate,
                judet: this.state.judet,
                numar: this.state.numar,
                bloc: this.state.bloc,
                scara: this.state.scara,
                etaj: this.state.etaj,
                apartament: this.state.apartament
            };
    
            // Ambele metode sunt valabile dar este o problema cu button type submit in firefox
    
            // fetch('http://127.0.0.1:8082/api/v1/student', {
            //     method: 'POST',
            //     headers: {
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify(data)
            // })
    
            axios.post('http://127.0.0.1:8082/api/v1/adresa', data)
            .then(response => console.log(this.response))
            .catch(error => {
                this.setState({ errorMessage: error.message });
                console.error('There was an error!', error);
            });


        }


    }
    

    render() {
        return(
            <Container>
            <h1 className="display-4">Editare adrea locatie</h1>
            <hr className="my-4"></hr>
            {/* <h3>{this.state.nume}</h3> */}
            {/* <h3>{this.state.dn}</h3> */}
            {/* <h3>{this.state.email}</h3> */}
            
            <Form onSubmit={this.handleSubmit}>
                <Form.Group controlId="formBasicText">
                    <Form.Label>Denumire </Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Denumire locatie" 
                        name="denumire" 
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicText">
                    <Form.Label>Strada</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Strada" 
                        name="strada"
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Cartier</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Cartier" 
                        name="cartier"
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Localitate</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Localitate" 
                        name="localitate"
                        onChange={this.handleChange}
                    />
                </Form.Group>                
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Judet</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Judet" 
                        name="judet"
                        onChange={this.handleChange}
                    />
                </Form.Group>                
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Numar</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Numar" 
                        name="numar"
                        onChange={this.handleChange}
                    />
                </Form.Group> 
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Bloc</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Bloc" 
                        name="bloc"
                        onChange={this.handleChange}
                    />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Scara</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Scara" 
                        name="scara"
                        onChange={this.handleChange}
                    />
                </Form.Group> 
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Etaj</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Etaj" 
                        name="etaj"
                        onChange={this.handleChange}
                    />
                </Form.Group> 
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>Apartament</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Apartament" 
                        name="apartament"
                        onChange={this.handleChange}
                    />
                </Form.Group>   
                <br />                      
                <Button variant="primary" type="submit">
                    Submit
                </Button>
            </Form>
            <hr className="my-4"></hr>
            </Container>
        )    

    }


}